import { useState, useEffect } from 'react';
// material
import { Card, Box, Grid, Container, Chip, Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { DataGrid } from '@mui/x-data-grid';
import { deleteVendor, getVendorList, updateVendorList } from '../../Redux-Store/actions/vendorActions';
import axiosInstanceVendor from '../../api/withJWTVendor';
import Page from '../../components/Page';
import { UserMoreMenu } from '../../sections/@dashboard/user';
import VendorDocModel from '../../Component/ModelBox/VendorDocModel';
import Status from '../../components/Status';
import Iconify from '../../components/Iconify';
import VendorBusinessImages from '../../Component/VendorBusinessImages';
import PagesTopBar from '../../Component/PagesTopBar';
import SwitchBtnReverse from '../../Component/DataGridComponents/SwitchBtnReverse';
import ShowMessage from '../../Component/ShowMessage';
import SuspendModelBox from '../../Component/ModelBox/SuspendModelBox';
import WithHoldModelBox from '../../Component/ModelBox/WithHoldModelBox';
import CommonDeleteBox from '../../Component/ModelBox/CommonDeleteBox';
import axiosInstance from '../../api/withJWTServer';
import { createPayment } from '../../api';
import PaymentModel from '../../Component/ModelBox/PaymentModel';

export default function Vendor() {
  const dispatch = useDispatch();

  // const usersString = ;
  const Updateduuid = localStorage.getItem('userUuid');

  const isLogged = localStorage.getItem('isLogged');
  const role = localStorage.getItem('role');

  console.log('users09898', Updateduuid);

  const { vendor, isloading, dataCount } = useSelector((state) => ({
    vendor: state.vendorInfo?.vendors?.data,
    isloading: state.vendorInfo?.isLoading,
  }));

  console.log('vendor', vendor);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [openBusinessImage, setOpenBusinessImage] = useState(false);
  const [businessImageData, setBusinessImageData] = useState(false);
  const [docsData, setDocsData] = useState([]);
  const [filterData, setFilterData] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [reason, setReason] = useState('');
  const [rejectReason, setRejectReason] = useState('');
  const [RejectParams, setRejectParams] = useState(null);
  const [openSuspendModel, setOpenSuspendModel] = useState(false);
  const [activeObject, setActiveObject] = useState({});
  const [isShow, setIsShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isShowUpdate, setIsShowUpdate] = useState(false);
  const [errorUpdate, setErrorUpdate] = useState(false);
  const [error, setError] = useState(false);
  const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });
  const [opendeleteModal, setOpendeleteModal] = useState(false);
  const [deleteuuid, setDeleteuuid] = useState('');

  const [serialNumber, setSerialNumber] = useState(0);
  const [DefaultPlanId, setDefaultPlanId] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState();
  const [currentPlan, setCurrentPlan] = useState();
  const [currentAmount, setCurrentAmount] = useState();
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [paymentObject, setPaymentObject] = useState();
  const [isPaymentShow, setIsPaymentShow] = useState(false);
  const [isSuspended, setIsSuspended] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);

  const getPeginationModelValue = (e) => {
    console.log('getPeginationModelValue', e);
    setSerialNumber(e.page * e.pageSize);
    setPaginationModel(e);
    setPage(e.page);
    setRowsPerPage(e.pageSize);
  };
  const pageState = {
    filterData,
    page,
    rowsPerPage,
  };

  useEffect(() => {
    dispatch(getVendorList(pageState));
  }, [paginationModel, filterData]);

  useEffect(() => {
    if (rejectReason) {
      setReason('');
    }
  }, [rejectReason]);

  const handleOpen = (uuid, action, isactive, rejectReason) => {
    handleActivate(uuid, action, isactive, rejectReason);
    setOpenModal(true);
  };
  const handleClose = () => {
    setReason('');
    setRejectReason(null);
    setOpenModal(false);
  };

  const handleNewUserClick = () => {
    setOpen(!open);
  };

  const handleBusinessImageClick = () => {
    setOpenBusinessImage(!openBusinessImage);
  };



  const today = new Date();

  // Get year, month, and day
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(today.getDate()).padStart(2, '0');
  
  // Format the date as YYYY-MM-DD
  const formattedTodayDate = `${year}-${month}-${day}`;
  
  console.log("formattedDate",formattedTodayDate)
  
  const handleActivate = (uuid, slug, isactive, rejectReson, isForSuspended = false) => {
    console.log('handleActivate', slug);

    const paramState = {
      uuid: uuid.toString(),
      isactive,
      status: slug ?? 'Application Initiated',
      isApproved: isForSuspended ? 'false' : 'true',
      source_type: isForSuspended ? 'vendor_suspended_status_true' : 'vendor_suspended_status_false',
      updaterUUID: Updateduuid,
      updaterName: role,
      issubscribed: '',
    };

    console.log('jhjhj', paramState);

    if (slug === 'With-Hold' && isForSuspended === false) {
      console.log('utyutyui');
      // setRejectParams('rejwctred');
      setRejectParams({
        uuid: uuid.toString(),
        isactive,
        status: slug,
        source_type: 'vendor_suspended_status_false',
        updaterUUID: Updateduuid,
        updaterName: role,
        issubscribed: '',
      });
    } else {
      dispatch(updateVendorList(paramState))
        .unwrap()
        .then((response) => {
          console.log('response in update reviewer', response);
          if (response.status === 200) {
            // setSubmitLoader(false);
            setIsShowUpdate(true);
            setErrorMessage(response.data.message);
            dispatch(getVendorList(pageState));
          }
          setTimeout(() => {
            setIsShowUpdate(false);
            // navigate(-1);
          }, 1000);
          setOpenSuspendModel(false);
          setActiveObject({});
        })
        .catch((err) => {
          setOpenSuspendModel(false);
          setActiveObject({});
        });
      // axiosInstance
      //   .patch('/admin/update-vendor', params)
      //   .then((res) => {
      //     // vendorList(filterData);
      //     setOpenSuspendModel(false);
      //     setActiveObject({});
      //   })
      //   .catch((err) => {
      //     setOpenSuspendModel(false);
      //     setActiveObject({});
      //   });
    }
  };

  const handleAction = (uuid, action, isactive) => {
    console.log('Data values', uuid, action, isactive);
    if (action === 'With-Hold') {
      handleOpen(uuid, action, isactive, rejectReason);
    } else if (action === 'Delete') {
      handledeleteOpen(uuid, action, isactive, rejectReason);
    } else {
      handleActivate(uuid, action, isactive, rejectReason);
    }
  };

  const handleReason = (e) => {
    // console.log('reson', e.target.value);
    setRejectReason(e.target.value);
  };

  const handleRejectReason = () => {
    console.log('rejectReason', rejectReason);
    if (rejectReason) {
      // setfullLoader(true);
      const params = {
        status: RejectParams.status,
        isactive: RejectParams.isactive,
        remarks: rejectReason,
        uuid: RejectParams.uuid,
        updaterUUID: Updateduuid,
        updaterName: role,
        issubscribed: '',
        isApproved: 'true',
        source_type: 'vendor_suspended_status_false',
      };

      // here need to call update api--------------
      dispatch(updateVendorList(params))
        .unwrap()
        .then((response) => {
          console.log('response in update reviewer', response);
          if (response.status === 200) {
            // setSubmitLoader(false);

            setIsShowUpdate(true);
            setErrorMessage(response.data.message);
            setReason('');
            setRejectReason(null);
            handleClose();
          }

          dispatch(getVendorList(pageState));

          setTimeout(() => {
            setIsShowUpdate(false);
            // navigate(-1);
          }, 1000);
        })
        .catch((err) => {
          setRejectParams(null);
          setReason('');
        });
      // axiosInstance
      //   .patch('admin/update-vendor', params)
      //   .then((res) => {
      //     dispatch(HandleExceptionWithSecureCatch(res));
      //     setRejectParams(null);
      //     // vendorList(filterData);
      //     handleClose();
      //     setReason('');
      //     // setfullLoader(false);
      //   })
      //   .catch((err) => {
      //     dispatch(HandleExceptionWithSecureCatch(err));
      //     setRejectParams(null);
      //     setReason('');
      //     // setfullLoader(false);
      //   });
    } else {
      setReason('Provide Reason');
    }
  };

  const handleSubmit = (param) => {
    console.log('activeObject', activeObject);
    handleActivate(activeObject?.uuid, activeObject?.status, activeObject?.isactive, '', true);

    setOpenSuspendModel(false);
  };

  const handleActiveDeactiveClick = (object) => {
    console.log('object89789', object);
    setActiveObject({
      uuid: object?.uuid,
      status: object?.status,
      isactive: object?.isactive === 1 ? 0 : 1,
    });
    setOpenSuspendModel(!openSuspendModel);
  };

  const handleSuspendClose = () => {
    setOpenSuspendModel(false);
    setActiveObject({});
  };

  const handleSearch = (value) => {
    console.log('search', value);
    setFilterData(value);
    setSearchValue(value);
  };
  const clearSearch = (value) => {
    pageState.filterData = '';
    dispatch(getVendorList(pageState));
  };
  const handledeleteOpen = (uuid, action, isactive, rejectReason) => {
    // handleActivate(uuid, action, isactive, rejectReason);
    setDeleteuuid(uuid);
    setOpendeleteModal(true);
  };

  const handleDeleteCancle = () => {
    setDeleteuuid('');
    setOpendeleteModal(false);
  };
  const handleDeleteSubmit = () => {
    dispatch(deleteVendor(deleteuuid))
      .unwrap()
      .then((response) => {
        setSubmitLoader(true);
        console.log('response in delete vendor', response);
        if (response.status === 200) {
          setIsShow(true);
          // setOpen(!open);
          setSubmitLoader(false);
          console.log('response', response);
          setErrorMessage(response.data.message);
          dispatch(getVendorList(pageState));
          setOpendeleteModal(false);
        }
        setTimeout(() => {
          setIsShow(false);
          // navigate(-1);
        }, 1500);
      })
      .catch((error) => {
        console.log('error');
        setSubmitLoader(false);
      });
  };

  const CustomNoRowsOverlay = () => {
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        {/* Your custom message here */}
        <h4>No Data Found</h4>
      </div>
    );
  };

  const columns = [
    {
      field: 'id',
      headerName: 'Sr no',
      width: 70,
      renderCell: (params) => serialNumber + params.api.getAllRowIds().indexOf(params.id) + 1,
    },
    {
      field: `Business Name`,
      headerName: 'Business Name',
      width: 250,
      renderCell: (params) => {
        // console.log('params', params?.row?.vendorBusinessDetailsModels[0]?.business_name);
        return (
          <div
            style={{
              whiteSpace: 'normal',
              wordWrap: 'break-word',
              display: 'flex',
              flexWrap: 'wrap',
              // paddingTop: '15px',
            }}
          >
            {params?.row?.vendorBusinessDetailsModels[0]?.business_name
              ? params?.row?.vendorBusinessDetailsModels[0]?.business_name
              : '---'}
          </div>
        );
      },
    },
    { field: `firstname`, headerName: 'Name', width: 200, type: 'string' },

    {
      field: 'Address',
      headerName: 'Address',
      width: 400,
      renderCell: (params) => {
        return (
          // console.log('hdtydywegtf', params?.formattedValue[0]);
          <div
            style={{
              whiteSpace: 'normal',
              wordWrap: 'break-word',
              // display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              // paddingTop: '15px',
            }}
          >
            {params?.row?.vendorBusinessDetailsModels[0]?.address_line_1
              ? params?.row?.vendorBusinessDetailsModels[0]?.address_line_1
              : ' ---'}
          </div>
        );
      },
    },

    { field: 'email', headerName: 'Email', width: 250, type: 'string' },
    {
      field: 'Business Category',
      headerName: 'Business Category',
      width: 150,
      renderCell: (params) => {
        // console.log('hdtydywegtf', params?.formattedValue[0]?.category_label);
        return (
          <div
            style={{
              whiteSpace: 'normal',
              wordWrap: 'break-word',
              display: 'flex',
              flexWrap: 'wrap',
              // paddingTop: '15px',
            }}
          >
            {params?.row?.vendorBusinessDetailsModels[0]?.category_label ? (
              params?.row?.vendorBusinessDetailsModels[0]?.category_label
            ) : (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                ---
              </Box>
            )}
          </div>
        );
      },
    },
    {
      field: 'mobile_number',
      headerName: 'Contact Number',
      width: 150,
      type: 'string',
      renderCell: (params) => (
        <Typography fontWeight={'400'} fontSize={'14px'}>
          {`+91-${params.row.mobile_number}`}
        </Typography>
      ),
    },
    {
      field: 'document',
      headerName: 'Uploaded Document',
      width: 170,

      renderCell: (params) =>
        params?.row?.vendorBusinessDocs?.length > 0 ? (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Iconify
              icon="eva:eye-fill"
              width={24}
              height={24}
              sx={{ marginLeft: '20px', color: 'green', cursor: 'pointer' }}
              onClick={() => {
                console.log('params.row?.vendorBusinessDocs', params.row);
                setOpen(!open);
                setDocsData(params.row?.vendorBusinessDocs);
                // setOpenBusinessImage(!open);
                // setBusinessImageData(params?.row?.vendorBusinessImages);
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            ---
          </Box>
        ),
    },

    {
      field: 'createdat',
      headerName: 'Created On',
      width: 180,
      renderCell: (params) => {
        console.log('7678678', params);

        return moment(params?.row?.createdat).format('DD/MM/YYYY hh:MM A');
      },
    },
    {
      field: 'activedeactivate',
      headerName: 'Suspended',
      width: 150,
      renderCell: (params) => (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <SwitchBtnReverse
            handleActiveDeactiveClick={(e) => handleActiveDeactiveClick(params.row)}
            statusData={params.row.isactive}
          />
        </Box>
      ),
    },
    {
      field: 'vendorBusinessImages',
      headerName: 'Business Images',
      width: 150,
      renderCell: (params) => {
        return params?.row?.vendorBusinessImages?.length > 0 ? (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Iconify
              icon="eva:eye-fill"
              width={24}
              height={24}
              sx={{ color: 'green', cursor: 'pointer' }}
              onClick={() => {
                setOpenBusinessImage(!open);
                setBusinessImageData(params?.row?.vendorBusinessImages);
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            ---
          </Box>
        );
      },
    },
    {
      field: 'status_updated_by',
      headerName: 'Action Taken By',
      width: 150,
      renderCell: (params) => {
        console.log('params?.rowuiyui', params?.row);
        return params?.row?.status_updated_by ? (
          <Chip
            label={`${params.row?.status_updated_by}`}
            style={{
              background: `${params?.row?.status_updated_by === 'Reviewer' ? '#eda923' : '#a364ea'}`,
              width: '100px',
              height: '28px',
              borderRadius: '4px',
              border: '1px solid #00000040',
              color: '#fff',
              fontSize: '12px',
              fontWeight: 700,
            }}
          />
        ) : (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            ....
          </Box>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 200,
      renderCell: (params) => {
        return params?.row?.status ? <Status status={params?.row?.status} /> : '---';
      },
    },
    {
      field: 'col5',
      headerName: 'Payment Link',
      width: 180,
      renderCell: (params) => {
        console.log('params in payment', params.row);

        if ((params?.row?.status === 'Active' || params.row.status === 'Renewal') && params?.row?.issubscribed === 0 && params?.row?.vendor_subscription_details[0]?.end_date< formattedTodayDate ) {
          return (
            <Button
              onClick={() => handleSendPaymentLink(params.row)}
              variant="outlined"
              style={{ padding: '3px 10px', fontSize: '12px' }}
              sx={{
                bgcolor: 'white',
                boxShadow: 'none',
                borderRadius: '6px',
                border: '1px solid green',
                '&:hover': { bgcolor: 'green', border: '1px solid green', color: 'white' },
              }}
            >
              Send Payment Link
            </Button>
          );
        }

       if( params?.row?.vendor_subscription_details[0]?.end_date< formattedTodayDate) {
        return(
          <Chip
          label="Expired"
          style={{
            background: '#EC5210',
            height: '28px',
            borderRadius: '4px',
            border: '1.5px solid #D32F31',
            color: 'White',
            fontSize: '12px',
            fontWeight: 700,
          }}
        />
        )
       }


 
  if (params?.row?.issubscribed === 1) {
          return (
            <Chip
              label="Payment Done"
              style={{
                background: 'teal',
                height: '28px',
                borderRadius: '4px',
                border: '1px solid teal',
                color: '#fff',
                fontSize: '12px',
                fontWeight: 700,
              }}
            />
          );
        } 
        
        return (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignSelf: 'center',
            }}
          >
            ---
          </Box>
        );
      },
    },

    {
      field: 'col6',
      headerName: 'Actions',
      width: 100,
      renderCell: (params) => (
        <UserMoreMenu
          actionTaken={handleAction}
          uuid={params?.row?.uuid}
          isactive={params.row?.isactive}
          vendordetail={params.row}
          showAllMenu={params.row.vendorBusinessDetailsModels[0]?.business_name}
        />
      ),
    },
  ];
  const rows = [];

  // console.log('currentPlan', currentPlan);
  const getDetailsVendor = (rowData) => {
    axiosInstance
      .get('/admin/vendor-details', {
        params: {
          uuid: rowData?.uuid,
        },
      })
      .then((res) => {
        if (res.data.data[0]?.vendorBusinessDetailsModels?.[0]) {
          setCurrentPlan(res.data.data[0]?.vendorBusinessDetailsModels?.[0]?.selected_plan_uuid);
        } else {
          setCurrentPlan();
        }
        setPaymentOpen(true);
      })
      .catch((err) => {});
  };

  const handleSendPaymentLink = (rowData) => {
    console.log('Data', rowData);
    if (rowData.isactive === 1) {
      getDetailsVendor(rowData);
      setPaymentObject(rowData);
    } else {
      setIsSuspended(true);
      setErrorMessage('Account is suspeded please unsuspend the account then proceed.');
    }
  };
  const currentDate = moment();

  const handlePaymentSubmit = (planID, planPrice) => {
    console.log('calling paymntty api', planID, planPrice);

    const paramsData = {
      vendor_uuid: paymentObject?.uuid,
      total_amount: planPrice,
      subscription_plan_uuid: planID,
      start_date: currentDate.format('YYYY-MM-DD'),
      end_date: currentDate.add(364, 'days').format('YYYY-MM-DD'),
    };

    console.log('paramsData', paramsData);
    setPaymentOpen(false);

    createPayment
      .post('/vendor/createPayment', paramsData)
      .then((res) => {
        console.log('response of payment', res);
        if (res.status === 200) {
          setPaymentOpen(false);
          setIsPaymentShow(true);
          setErrorMessage('Payment link send successfully !');
          setTimeout(() => {
            setIsPaymentShow(false);
            // navigate(-1);
          }, 1500);

          // dispatch(HandlePaymentMessage('Payment link send sucessfully.', 200));
        } else {
          setPaymentOpen(false);
          setIsPaymentShow(true);
          setError(true);
          setErrorMessage(res.data.message);
          setTimeout(() => {
            setIsPaymentShow(false);
            // navigate(-1);
          });
        }
      })
      .catch((error) => {
        console.log('response of error', error);
        // dispatch(HandlePaymentMessage('Someting went wrong.', 400));
      });
  };

  const handlePaymentCancel = () => {
    setPaymentOpen(false);
    setPaymentObject({});
  };

  const handlePlanChange = (planId) => {
    setSelectedPlan(planId);
  };

  const handleDefaultPlanId = (defaultPlanId) => {
    setDefaultPlanId(defaultPlanId);
  };

  return (
    <>
      <Page title="User" sx={{ width: '100%' }}>
        {/* ---------------Payment model------------ */}
        {paymentOpen ? (
          <PaymentModel
            handlePaymentSubmit={handlePaymentSubmit}
            DefaultPlanId={handleDefaultPlanId}
            currentPlan={currentPlan}
            currentAmount={currentAmount}
            selectedPlan={selectedPlan}
            handlePlanChange={handlePlanChange}
            isPaymentOpen={paymentOpen}
            paymentObject={paymentObject}
            handlePaymentCancel={handlePaymentCancel}
            type="vendor"
          />
        ) : null}
        {/* ----------------Suspended Model------------------- */}
        {openSuspendModel ? (
          <SuspendModelBox
            isOpen={openSuspendModel}
            handleSuspendClose={handleSuspendClose}
            handleSuspend={handleSubmit}
            handleSuspendCancel={handleSuspendClose}
            activeObject={activeObject}
          />
        ) : null}

        {/* -----------With hold modl--------- */}
        {openModal ? (
          <WithHoldModelBox
            reason={reason}
            handleReason={handleReason}
            openModal={openModal}
            handleClose={handleClose}
            handleRejectReason={handleRejectReason}
          />
        ) : null}

        {/* -------------Vendor doc model------------------- */}
        {open ? <VendorDocModel isOpen={open} docs={docsData} handleClose={handleNewUserClick} /> : null}

        {/* ----------------Busness Images----------------------- */}
        {openBusinessImage ? (
          <VendorBusinessImages
            isOpen={openBusinessImage}
            docs={businessImageData}
            handleClose={handleBusinessImageClick}
          />
        ) : null}

        {/* ------------------Delete model ----------------- */}
        {opendeleteModal ? (
          <CommonDeleteBox
            submitLoader={submitLoader}
            openDialog={opendeleteModal}
            handleDeleteYes={handleDeleteSubmit}
            handleClose={handleDeleteCancle}
            title="Vendor"
            description="Are you sure you want to delete Vendor ?"
          />
        ) : (
          ''
        )}

        <Container sx={{ width: '100%' }} maxWidth="xl">
          {/* ------------------Page Top bar--------------------- */}
          <PagesTopBar
            vendor
            excel
            Add={false}
            title="Vendors"
            subTitle="List of all Vendors in your app."
            handleSearch={handleSearch}
            clearInput={() => {
              clearSearch();
            }}
          />
          {/* ------------------Data grid---------------------------- */}
          <Card sx={{ marginTop: '25px' }}>
            <Grid container>
              <Grid item lg={12} xs={12} sm={12}>
                <DataGrid
                  disableColumnMenu
                  style={{
                    padding: '10px',
                    height: 500,
                    width: '100%',
                    borderRadius: '10px',
                    fontSize: '14px',
                  }}
                  sx={{}}
                  paginationModel={paginationModel}
                  onPaginationModelChange={getPeginationModelValue}
                  pageSizeOptions={[10, 20, 30]}
                  paginationMode="server"
                  loading={isloading}
                  rows={vendor?.data ? vendor?.data : rows}
                  columns={columns}
                  getRowId={(row) => row.uuid}
                  rowCount={vendor?.data_count}
                  localeText={{ noRowsLabel: <CustomNoRowsOverlay /> }}
                  slots={
                    {
                      // toolbar: CustomToolbar,
                    }
                  }
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: {
                        variant: 'outlined',
                        size: 'small',
                        sx: {
                          bgcolor: '#ffffff',
                          // borderRadius: '8px',
                          // border: '1px solid rgba(0,0,0,0.1)',
                          pb: '0px',
                          '& fieldset': { border: 'none' },
                        },
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Card>
        </Container>

        {/* ---------------------Message Box---------------------- */}
        <ShowMessage
          onClose={() => setIsShowUpdate(false)}
          title={errorMessage}
          type={errorUpdate ? 'error' : 'success'}
          open={isShowUpdate}
        />
        <ShowMessage onClose={() => setIsShow(false)} title={errorMessage} type="success" open={isShow} />

        <ShowMessage onClose={() => setIsSuspended(false)} title={errorMessage} type="error" open={isSuspended} />

        {/* payment message */}
        <ShowMessage onClose={() => setIsPaymentShow(false)} title={errorMessage} type="success" open={isPaymentShow} />
      </Page>
    </>
  );
}
